@use '@/styles/utils/mixins.scss' as *;

.successCard {
  height: 100%;
  width: 100%;
  border-radius: 1.25rem;
  -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  padding: 2.5rem 1.875rem;
  display: flex;
  flex-direction: column;
  background-color: $white;
  transition: all 0.2s ease-in-out;

  .successCardContent {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
    @include tab() {
      flex-direction: column;
      align-items: center;
    }

    .successCardTitle {
      display: flex;
      text-align: left;
      flex-direction: column;
      color: $black;
    }

    .description {
      text-align: left;
      width: 100%;
    }

    .successImageContainer {
      position: relative;
      overflow: hidden;
      border-radius: 1.25rem;
      width: 100%;
      height: 21.25rem;
      max-height: 21.25rem;
    }

    .successCardImage {
      width: 100%;
      height: 100%;
    }
  }
}
